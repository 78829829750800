import i18n from "./i18n";


function App() {
  return (
    <div className="container-scroller">
    <div style={{
      display : 'flex',
      justifyContent : 'center'
    }} className="container-fluid page-body-wrapper full-page-wrapper">
      <div className="content-wrapper d-flex align-items-center auth lock-full-bg">
        <div className="row w-100">
          <div className="col-lg-4 mx-auto" style={{
            display : 'flex',
            justifyContent : 'center',
            // alignItems : 'center',
            flexDirection : 'column'
          }}>

            <img alt="bolepo-icon" style={{
              display : 'flex',
              alignSelf : 'center',
              height : 60,


              borderRadius : 10
              // width : 140
            }} src="/images/appstore.png" />
            <div className="auth-form-transparent text-left text-center">
              
              
                <div className="mt-5">
                  <a className="btn btn-block btn-lg font-weight-medium" style={{
                    backgroundColor : "#FF9FCA",
                    color : "#fff",
                    fontSize : 30
                  }} target="_blank" rel="noreferrer" href="https://business.mabali.net/orders">{"ניהול הזמנות, תפריט ופעילות‎"}</a>
                </div>
                <div className="mt-5">
                  <a className="btn btn-block btn-lg font-weight-medium" style={{
                    backgroundColor : "#FF9FCA",
                    color : "#fff",
                    fontSize : 30
                  }} target="_blank" rel="noreferrer" href="https://business-manager.mabali.net">{i18n.t("business-site")}</a>
                </div>
                <div className="mt-5">
                  <a className="btn btn-block white-text btn-lg font-weight-medium" style={{
                    backgroundColor : "#FF9FCA",
                    color : "#fff",
                    fontSize : 30
                  }} target="_blank" rel="noreferrer" href="https://driver-manager.mabali.net/managers">{i18n.t("driver-manager-site")}</a>
                </div>
                <div className="mt-5">
                  <a className="btn btn-block btn-lg font-weight-medium" style={{
                    backgroundColor : "#FF9FCA",
                    color : "#fff",
                    fontSize : 30
                  }} target="_blank" rel="noreferrer" href="https://owner.mabali.net">{i18n.t("owners-site")}</a>
                </div>
              
            </div>
            
          </div>
          
        </div>
        
      </div>
      <a style={{
        position : 'absolute',
        bottom : 40,
        display : 'flex',
        color : "#fff",
        alignSelf : 'center',
        textDecoration : 'underline'
      }} href="mailto:info@menual.net?subject=Support%20Request" >Need Support? Click Here</a>
     
    </div>
    
  </div>
  );
}

export default App;
